<template>
  <div class="hfull">
    <div class="header pt-lg-5"></div>

    <b-container class="mt-5 pb-5">
      <b-row class="justify-content-center">
        <b-col xl="12">
          <b-card>
            <template>
              <h6 class="surtitle">Profile Perusahaan</h6>
              <h5 class="h2 mb-0">{{ listData.company.name }}</h5>
              <div class="card-body">

                <div class="mt-4">
                  <span class="surtitle"><b>Perusahaan</b></span>
                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Jenis Perusahaan</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.company.comp_type_name}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Alamat</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.company.address}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Alamat Lengkap</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.company.street}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Provinsi</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.company.province_name}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Kabupaten/Kota</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.company.kabkot_name}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Email</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.company.email}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Telepon</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.company.phone}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Penanggung Jawab SIUP</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.company.siup_pj}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Dokumen SIUP</strong>
                        </div>
                        <div class="col-lg-9">
                          <a :href="getBlobsFile(listData.company.siup_file_id)" target="blank">
                              <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                              <span>{{
                                listData.company.siup_file_id === "" ||
                                listData.company.siup_file_id === undefined ||
                                listData.company.siup_file_id === null
                                  ? " Belum ada  File Terupload"
                                  : " File Terupload"
                              }}</span>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Dokumen Koneksi</strong>
                        </div>
                        <div class="col-lg-9">
                          <a :href="getBlobsFile(listData.company.koneksi_file_id)" target="blank">
                              <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                              <span>{{
                                listData.company.koneksi_file_id === "" ||
                                listData.company.koneksi_file_id === undefined ||
                                listData.company.koneksi_file_id === null
                                  ? " Belum ada  File Terupload"
                                  : " File Terupload"
                              }}</span>
                          </a>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Dibuat pada</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ formatDate(listData.company.created_at)}}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="mt-4">
                  <span class="surtitle"><b>Pengguna</b></span>
                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData.user.name }}</span>
                        </div>
                      </div>
                    </li>

                    

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Email</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData.user.email }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Telepon</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData.user.phone }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Aksi</strong>
                        </div>
                        <div class="col-lg-9">
                          <button class="btn btn-primary" @click="changePasswordModal = true">Rubah Password</button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <!-- Aktivitas -->
                <div class="mt-4">
                  <span class="surtitle" ><b>Aktivitas</b></span>
                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Jumlah titik penaatan Sparing</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.jumlah_pendaftaran_sparing }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Jumlah titik penaatan</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.jumlah_titik_penaatan }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Jumlah Pendaftaran Uji Konek</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.jumlah_pendaftaran_uji_konektivitas }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Jumlah Pendaftaran Pelaporan Progress</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.jumlah_pendaftaran_pelaporan_progress }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Jumlah Pendaftaran Pembuangan air limbah</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.jumlah_pendaftaran_pembuangan_air_limbah }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Jumlah Laporan manual data satu minggu</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.jumlah_laporan_manual_data_satu_minggu }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Jumlah Laporan kondisi tidak normal</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.jumlah_laporan_kondisi_tidak_normal }}</span>
                        </div>
                      </div>
                    </li>


                    
                  </ul>
                </div>

              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <Modal
        :show="changePasswordModal"
        modalContentClasses="mdl-content"
        modalClasses="modal-dialog-scrollable"
        headerClasses="pb-0"
        @close="changePasswordModal = false"
        size="lg"
      >
      <template v-slot:header>
          <div class="modal-title">
            <h4 class="mdl-title">Rubah Password</h4>
          </div>
        </template>
        <div class="px-4 border-top pt-4">
          <div class="form-group row">
            <div class="col-lg-12">
              <label class="col-form-label form-control-label">Password Lama</label>
              <div>
                <input type="text" class="form-control" v-model="old_password" />
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-lg-12">
              <label class="col-form-label form-control-label">Password Baru</label>
              <div>
                <input type="text" class="form-control" v-model="new_password" />
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-lg-12">
              <label class="col-form-label form-control-label">Konfirmasi Password Baru</label>
              <div>
                <input type="text" class="form-control" v-model="new_password_confirmation" />
              </div>
            </div>
          </div>
          
  
          
          <div class="form-group row">
            <button class="btn btn-primary" @click="changePassword">RUBAH</button>
            <button class="btn btn-danger" @click="changePasswordModal = false">BATAL</button>
          </div>
        </div>
    </Modal>
  </div>
</template>

<script>
import API from "@/api/base_url.js";
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import axios from 'axios'
import FileInput from "@/components/Inputs/FileInput";
import baseURL from "@/api/base_url_backend.js";
import moment from "moment";

export default {
  name: "informasiPerusahaan",
  components: {
    FileInput,
    Modal
  },
  mounted() {
    this.getData()
  },
  data() {
    return {
      myForm: new FormData(),
      changePasswordModal: false,
      old_password: "",
      new_password: "",
      new_password_confirmation: "",
      listData: {
        company : {
          id: "",
          name: "",
          phone: "",
          email: "",
          address: "",
          address_alternative: "",
          siup_number: "",
          siup_on_behalf: "",
          siup_file_path: "",
          connection_request_file_path: "",
          company_type_id: "",
          company_type: "",
          province_id: "",
          province: "",
          regency_id: "",
          regency: "",
          created_at: "",
        },
        user: {
          id: "",
          company_id: "",
          role_id: "",
          role_name: "",
          name: "",
          email: "",
          phone: "",
          address: "",
          status: 0,
          created_at: ""
        },
        jumlah_pendaftaran_sparing: "",
        jumlah_titik_penaatan: "",
        jumlah_pendaftaran_uji_konektivitas: "",
        jumlah_pendaftaran_pelaporan_progress: "",
        jumlah_pendaftaran_pembuangan_air_limbah: "",
        jumlah_laporan_manual_data_satu_minggu: "",
        jumlah_laporan_kondisi_tidak_normal: ""
      }
    }
  },
  methods: {
    formatDate(timestamp) {
      if (timestamp == 0) {
        return "-"
      }

      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY');
      return humanDate;
    },
    getBlobsFile(filename) {
      let url = `${baseURL}blobs/download/${filename}`
      return url
    },
    submitData() {
      this.myForm.append("company_one","Samsung");
      this.myForm.append("company_two","Vivo");
      this.myForm.append("company_three","Apple");

      axios.post('dummy', this.myForm)
        .then(({data}) => {
          // reset all v-model input here
          this.myForm = new FormData()
        })
        .catch((err) => {
          this.myForm.delete("company_one");
          this.myForm.delete("company_two");
          this.myForm.delete("company_three");
        })
    },
    uploadFile(category, field) {
      console.log(this.myForm.get(field),'--->');
      if (this.myForm.get(field) !== null) {
        // Replace
        this.myForm.delete(field)
        this.myForm.append(field, this.$refs[field].files[0]);
      } else {
        // New
        this.myForm.append(field, this.$refs[field].files[0]);
      }
    },
    getData() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`comps/profile` , {headers})
          .then(({data}) => {
            if (data.data == null) {

            } else {
              this.listData = data.data
            }
           
          })
          .catch((err) => {
            console.log(err);
        })
    },
    changePassword() {

        if (this.new_password !== this.new_password_confirmation) {
          Swal.fire('Password tidak sama','','error')
        } else {


          Swal.fire({
          title: 'Rubah Password ? ',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'RUBAH',
          cancelButtonText: 'BATAL'
        }).then((result) => {
          if (result.isConfirmed) {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }

            let payload = {
              new_password: this.new_password,
              old_password: this.old_password
            }

            API.post(`auth/change-password` , payload, {headers})
            .then(({data}) => {
              // delete localStorage

              this.new_password = ""
              this.new_password_confirmation = ""
              this.old_password = ""
              
              this.changePasswordModal = false

              localStorage.removeItem('token')
              localStorage.removeItem('email')
              localStorage.removeItem('as')
              this.$router.push('/')

              Swal.fire('Password berhasil dirubah','anda wajib masuk terlebih dahulu','success')
            })
            .catch((err) => {
                Swal.fire('Gagal Merubah Password','','error')
                console.log(err);
            })

            
          }
        })

        }

    }
  },
};
</script>
